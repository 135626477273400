.contact-container {
  min-height: 100vh;
  width: 100%;
  max-width: 800px;
  margin: 0 auto;
  padding: 40px;
  background: white;
  display: flex;
  flex-direction: column;
  align-items: stretch;
}

.contact-container p {
  margin: 0;
  padding: 10px;
}

.contact-container a {
  color: #007bff;
  text-decoration: none;
}

.contact-container a:hover {
  text-decoration: underline;
}

.email-link {
  display: inline; /* Ensure the link stays on the same line */
}
.contact-container h2 {
  text-align: center;
  color: #2c3e50;
  margin-bottom: 40px;
  font-size: 32px;
  font-weight: 600;
}

.contact-form {
  display: grid;
  gap: 24px;
  background: #f8f9fa;
  padding: 30px;
  border-radius: 8px;
  box-shadow: 0 2px 12px rgba(0, 0, 0, 0.1);
  margin-bottom: 40px;
}

.form-group {
  display: flex;
  flex-direction: column;
  gap: 8px;
}

.form-group label {
  font-weight: 500;
  color: #2c3e50;
  font-size: 14px;
  text-transform: uppercase;
  letter-spacing: 0.5px;
}

.form-group input,
.form-group select,
.form-group textarea {
  padding: 12px 16px;
  border: 2px solid #e9ecef;
  border-radius: 8px;
  font-size: 16px;
  transition: all 0.2s ease;
  background: white;
}

.form-group input:hover,
.form-group select:hover,
.form-group textarea:hover {
  border-color: #cbd3da;
}

.form-group input:focus,
.form-group select:focus,
.form-group textarea:focus {
  outline: none;
  border-color: #007bff;
  box-shadow: 0 0 0 4px rgba(0, 123, 255, 0.15);
}

.form-group textarea {
  resize: vertical;
  min-height: 120px;
}

/* Make the first row (type and name) appear side by side */
.form-group:nth-child(1),
.form-group:nth-child(2) {
  display: inline-block;
  width: calc(50% - 12px);
}

.form-group:nth-child(1) {
  margin-right: 24px;
}

/* Make email and verify email appear side by side */
.form-group:nth-child(3),
.form-group:nth-child(4) {
  display: inline-block;
  width: calc(50% - 12px);
}

.form-group:nth-child(3) {
  margin-right: 24px;
}

.status-message {
  padding: 16px;
  border-radius: 8px;
  text-align: center;
  font-weight: 500;
  margin-bottom: 20px;
}

.status-message.success {
  background-color: #d4edda;
  color: #155724;
  border: none;
}

.status-message.error {
  background-color: #f8d7da;
  color: #721c24;
  border: none;
}

.submit-button {
  padding: 14px 28px;
  background-color: #007bff;
  color: white;
  border: none;
  border-radius: 8px;
  font-size: 16px;
  font-weight: 600;
  cursor: pointer;
  transition: all 0.2s ease;
  margin-top: 10px;
  width: 100%;
  text-transform: uppercase;
  letter-spacing: 1px;
}

.submit-button:hover {
  background-color: #0056b3;
  transform: translateY(-1px);
  box-shadow: 0 4px 12px rgba(0, 86, 179, 0.2);
}

.submit-button:active {
  transform: translateY(0);
  box-shadow: none;
}

.submit-button:disabled {
  background-color: #e9ecef;
  color: #adb5bd;
  cursor: not-allowed;
  transform: none;
  box-shadow: none;
}

/* Responsive design */
@media (max-width: 768px) {
  .contact-container {
    padding: 20px;
  }

  .contact-form {
    padding: 20px;
  }

  /* Stack all form groups on mobile */
  .form-group:nth-child(1),
  .form-group:nth-child(2),
  .form-group:nth-child(3),
  .form-group:nth-child(4) {
    display: block;
    width: 100%;
    margin-right: 0;
  }
}

