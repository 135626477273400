/* src/SignInSignUp.css */

/* Container Styles */
.auth-container {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 60vh;
    background-color: black;
  }
  
  /* Form Styles */
  .auth-form {
    background-color: #ffffff;
    border-radius: 8px;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
    padding: 2rem;
    max-width: 400px;
    width: 100%;
  }
  
  .auth-form h1 {
    text-align: center;
    margin-bottom: 1.5rem;
    color: #333;
    font-size: 1.8rem;
  }
  
  .auth-form input {
    width: 100%;
    padding: 0.75rem;
    margin-bottom: 1rem;
    border: 1px solid #ddd;
    border-radius: 4px;
    font-size: 1rem;
    box-sizing: border-box;
  }
  
  .auth-form button {
    width: 100%;
    padding: 0.75rem;
    background-color: #007bff;
    border: none;
    border-radius: 4px;
    color: #fff;
    font-size: 1rem;
    cursor: pointer;
    transition: background-color 0.3s ease;
  }
  
  .auth-form button:hover {
    background-color: #0056b3;
  }
  
  /* Error Message Styles */
  .auth-form p {
    color: red;
    font-size: 0.9rem;
    text-align: center;
  }
  
  /* Success Message Styles */
  .auth-form .success-message {
    color: green;
    font-size: 0.9rem;
    text-align: center;
  }
  .auth-form p {
    font-size: 14px;
    color: #333;
  }
  
  .auth-form .error-message {
    color: red;
    margin-bottom: 10px;
  }

.auth-form button:disabled {
  cursor: not-allowed;
  opacity: 0.7;
}

.auth-form select {
  width: 100%;
  padding: 10px;
  margin-bottom: 15px;
  border: 1px solid #ccc;
  border-radius: 4px;
  background-color: white;
  font-size: 16px;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, sans-serif;
  height: 40px;
  box-sizing: border-box;
  color: #000;  /* Default color for selected values */
}

/* Make the select gray when the placeholder is selected */
.auth-form select:invalid,
.auth-form select option[value=""],
.auth-form select:required:invalid {
  color: #757575;
}

/* Keep other options black in dropdown */
.auth-form select option:not([value=""]) {
  color: #000;
}

.auth-form select:focus {
  outline: none;
  border-color: #007bff;
}

/* For RTL support */
[dir="rtl"] .auth-form select {
  text-align: right;
}

/* Style the placeholder option to be gray */
.auth-form select option[value=""] {
  color: #757575;  /* Gray color for placeholder */
}

/* Style all other options to be black */
.auth-form select option:not([value=""]) {
  color: #000;  /* Black color for actual values */
}

.auth-form select option {
  color: #333;
  font-size: 16px;  /* Match input font size */
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, sans-serif;
  padding: 10px;  /* Match input padding */
}