.dialog-overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 2000;
}

.dialog-content {
  background-color: white;
  padding: 20px;
  border-radius: 8px;
  min-width: 400px;
  max-width: 600px;
  max-height: 80vh;
  overflow-y: auto;
  color: black;
  position: relative;
  z-index: 2001;
}

.dialog-field {
  margin: 15px 0;
}

.dialog-field label {
  display: block;
  margin-bottom: 5px;
  font-weight: bold;
  color: black;
}

.dialog-field input {
  width: 100%;
  padding: 8px;
  border: 1px solid #ddd;
  border-radius: 4px;
  color: black;
}

.dialog-buttons {
  margin-top: 20px;
  display: flex;
  justify-content: flex-end;
  gap: 10px;
}

.dialog-buttons button {
  padding: 8px 16px;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  font-weight: 500;
  color: white;
}

.cancel-button {
  background-color: #6c757d;
  color: white;
}

.simple-duplicate-button {
  background-color: #007bff;
  color: white;
}

.full-duplicate-button {
  background-color: #28a745;
  color: white;
}

.dialog-buttons button:hover {
  opacity: 0.9;
}

.dialog-content h2,
.dialog-content h3 {
  color: black;
}

.solution-dialog {
  width: 80%;
  max-width: 1000px;
}

.solution-dialog .editor-container {
  margin: 20px 0;
  border: 1px solid #ddd;
  border-radius: 4px;
}

.save-button {
  background-color: #28a745;
  color: white;
}

.dialog-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 20px;
}

.dialog-header h2 {
  margin: 0;
}

.dialog-header .dialog-buttons {
  margin: 0;
}

.solution-dialog .editor-container {
  margin: 0;
  border: 1px solid #ddd;
  border-radius: 4px;
}

.tags-dialog {
  min-width: 500px;
}

.tags-list {
  max-height: 400px;
  overflow-y: auto;
  margin: 20px 0;
}

.tag-item {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 8px;
  border-bottom: 1px solid #eee;
  cursor: pointer;
  position: relative;
  z-index: 2002;
}

.tag-check {
  margin-left: 8px;
  color: white;
  font-weight: bold;
}

.tag-actions {
  display: flex;
  gap: 8px;
  position: relative;
  z-index: 2003;
}

.new-tag-form {
  display: flex;
  gap: 8px;
  margin: 16px 0;
  padding: 16px;
  background: #f5f5f5;
  border-radius: 4px;
}

/* Common styles for action buttons in cells */
.manage-tags-button,
.edit-solution-button,
.edit-text-button {
  width: 24px;          /* Change this to make buttons wider/narrower */
  height: 24px;         /* Change this to make buttons taller/shorter */
  padding: 0;           /* Remove padding */
  margin: 0;            /* Remove margin */
  display: flex;        /* Use flex for centering */
  align-items: center;  /* Center vertically */
  justify-content: center; /* Center horizontally */
  background-color: #007bff !important;
  color: white !important;
  border: none !important;
  border-radius: 4px;
  cursor: pointer;
}

/* Make the icons consistent size */
.edit-solution-button i,
.edit-text-button i {
  font-size: 14px;    /* Change this to make the icons bigger/smaller */
}

.edit-solution-button:hover,
.edit-text-button:hover {
  background-color: #0056b3 !important;
}

.delete-tag-button {
  background-color: #dc3545 !important;
  color: white !important;
  border: none !important;
  padding: 4px 8px;
  border-radius: 4px;
  cursor: pointer;
}

.delete-tag-button:hover {
  background-color: #c82333 !important;
}

.tags-dialog button {
  position: relative;
  z-index: 2003;
  cursor: pointer;
}

.edit-tag-button {
  background-color: #ffc107 !important;
  color: white !important;
  border: none !important;
  padding: 4px 8px;
  border-radius: 4px;
  cursor: pointer;
  margin: 0 4px;
}

.edit-tag-button:hover {
  background-color: #e0a800 !important;
}

.edit-tag-dialog {
  min-width: 300px;
  z-index: 3000 !important;
}

.edit-tag-dialog-overlay {
  z-index: 2999 !important;
}

.tags-header {
  display: flex;
  align-items: center;
  gap: 8px;
}

.tag-filter-button, .clear-filter-button {
  padding: 4px 8px;
  background: none !important;
  border: none !important;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
}

.tag-filter-button {
  color: #007bff !important;
}

.tag-filter-button:hover {
  color: #0056b3 !important;
}

.clear-filter-button {
  color: #dc3545 !important;
}

.clear-filter-button:hover {
  color: #c82333 !important;
}

/* Change the Apply Filter button color in the dialog */
.filter-button {
  background-color: #007bff !important;
  color: white !important;
  border: none !important;
  padding: 8px 16px;
  border-radius: 4px;
  cursor: pointer;
}

.filter-button:hover {
  background-color: #0056b3 !important;
}

.tag-filter-dialog .tag-item {
  cursor: pointer;
  padding: 8px;
  border-radius: 4px;
  margin: 4px 0;
}

.tag-filter-dialog .tag-item.selected {
  background-color: #e9ecef;
}

.operator-selector {
  margin: 16px 0;
  padding: 8px;
  background-color: #f8f9fa;
  border-radius: 4px;
}

/* Style for the new tag button */
.new-tag-button {
  background-color: #007bff !important;
  color: white !important;
  border: none !important;
  padding: 8px 16px;
  border-radius: 4px;
  cursor: pointer;
}

.new-tag-button:hover {
  background-color: #0056b3 !important;
} 