/* Progress bar container */
.progress-container {
    margin: 20px 0;
    padding: 10px;
    background-color: #f5f5f5;
    border-radius: 8px;
    box-shadow: 0 2px 4px rgba(0,0,0,0.1);
    display: flex;
    align-items: center;
    gap: 15px;
  }
  
  /* Progress bar fill */
  .progress-bar {
    flex-grow: 1;
    height: 24px;
    background-color: #e0e0e0;
    border-radius: 12px;
    overflow: hidden;
    border: 1px solid #ddd;
  }
  
  /* Progress bar fill */
  .progress-fill {
    height: 100%;
    background-color: #8e58ad;
    transition: width 0.3s ease-in-out;
  }
  
  /* Percentage text inside the progress bar */
  .progress-text {
    min-width: 60px;
    font-size: 16px;
    font-weight: bold;
    color: #666;
  }
  
  .admin-page {
    padding: 20px;
    width: 90%;
    max-width: 1400px;
    margin: 0 auto;
    box-sizing: border-box;
  }
  
  /* Section styling */
  .questions-section,
  .tests-section,
  .testables-section {
    padding: 20px 0;
    width: 100%;
    margin: 0 auto;
    background: white;
    border-radius: 10px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
    display: flex;
    flex-direction: column;
  }
  
  /* Table container */
  .table-container {
    width: 100%;
    margin: 0 auto;
    padding: 0;
    overflow-x: auto;
  }
  
  .section-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin: 0 5px 20px 5px;
    padding-bottom: 15px;
    border-bottom: 2px solid #f0f0f0;
  }
  
  .section-header h3 {
    margin: 0;
  }
  
  /* Table styling */
  .admin-table {
    table-layout: fixed;
    width: 100%;
  }
  
  /* Update table cell colors */
  .admin-table td,
  .admin-table th {
    padding: 8px;
    border: 1px solid #ddd;
    text-align: left;
    color: #000000;  /* Force black text */
  }
  
  /* Make sure inputs and selects also have black text */
  .admin-table input,
  .admin-table select,
  .admin-table textarea {
    color: #000000;
    background-color: #ffffff;
    width: 100%;
    padding: 4px;
    border: 1px solid #ddd;
    border-radius: 4px;
  }
  
  /* Keep action buttons white text */
  .table-actions button {
    color: #ffffff;  /* Keep button text white */
  }
  
  /* Ensure selected row text remains visible */
  .admin-table tr.selected {
    background-color: #fff3cd;
    color: #000000;
  }
  
  /* Ensure hover state text remains visible */
  .admin-table tr:hover {
    background-color: #f9f9f9;
    color: #000000;
  }
  
  /* Make sure checkbox labels are visible */
  .admin-table input[type="checkbox"] + label {
    color: #000000;
  }
  
  .admin-table th {
    background-color: #f5f5f5;
    font-weight: bold;
  }
  
  .admin-table tr:hover {
    background-color: #f9f9f9;
  }
  
  .admin-table tr.selected {
    background-color: #fff3cd;
  }
  
  .table-actions {
    white-space: nowrap;
  }
  
  .table-actions button {
    margin: 0 4px;
    padding: 4px 8px;
    border: none;
    border-radius: 4px;
    cursor: pointer;
  }
  
  /* Table action button colors */
  .table-actions button.details {
    background-color: #28a745;  /* Green */
    border-color: #28a745;
    color: white;
  }
  
  .table-actions button.edit {
    background-color: #007bff;  /* Blue */
    border-color: #007bff;
    color: white;
  }
  
  .table-actions button.delete {
    background-color: #dc3545;  /* Red */
    border-color: #dc3545;
    color: white;
  }
  
  /* Hover states */
  .table-actions button:hover:not(:disabled) {
    opacity: 0.9;
  }
  
  .admin-table input,
  .admin-table select {
    width: 100%;
    padding: 4px;
    border: 1px solid #ddd;
    border-radius: 4px;
  }
  
  /* Form controls */
  .admin-table input,
  .admin-table textarea,
  .admin-table select {
    text-align: left;
    direction: ltr;
    width: calc(100% - 2px) !important;
    padding: 1px 2px !important;
    box-sizing: border-box;
    max-width: 100%;
    padding: 12px 15px;
    min-height: 100px;
    border: 1px solid #ddd;
    border-radius: 4px;
    font-size: 14px;
    transition: border-color 0.2s;
    color: #000000;
  }
  
  .admin-table input:focus,
  .admin-table textarea:focus,
  .admin-table select:focus {
    border-color: #4a90e2;
    outline: none;
    box-shadow: 0 0 0 2px rgba(74, 144, 226, 0.2);
  }
  
  .admin-table textarea {
    padding: 2px;
    height: 60px;
    width: calc(100% - 4px);
    box-sizing: border-box;
  }
  
  .admin-table input[type="checkbox"] {
    width: 18px;
    height: 18px;
    margin: 0 auto;
    display: block;
    cursor: pointer;
  }
  
  /* Buttons */
  button {
    padding: 8px 16px;
    border-radius: 6px;
    font-weight: 500;
    cursor: pointer;
    transition: all 0.2s;
    border: none;
  }
  
  /* Add button */
  .section-header button {
    background-color: #4a90e2;
    color: white;
    font-size: 14px;
  }
  
  .section-header button:hover {
    background-color: #357abd;
    transform: translateY(-1px);
  }
  
  /* Action buttons */
  .admin-table button {
    padding: 2px 4px !important;
    margin: 0 1px !important;
    font-size: 0.85rem !important;
  }
  
  
  /* Save button */
  .admin-table button.save {
    background-color: #28a745;
    color: white;
  }
  
  .admin-table button.save:hover {
    background-color: #218838;
  }
  
  /* Cancel button */
  .admin-table button.cancel {
    background-color: #6c757d;
    color: white;
  }
  
  .admin-table button.cancel:hover {
    background-color: #5a6268;
  }
  
  /* Status messages */
  .status-message {
    position: fixed;
    top: 20px;
    right: 20px;
    padding: 15px 20px;
    border-radius: 4px;
    animation: slideIn 0.3s ease-out;
    z-index: 1000;
    display: flex;
    align-items: center;
    gap: 10px;
    box-shadow: 0 2px 5px rgba(0,0,0,0.2);
  }
  
  .status-message.success {
    background-color: #d4edda;
    color: #155724;
    border: 1px solid #c3e6cb;
  }
  
  .status-message.error {
    background-color: #f8d7da;
    color: #721c24;
    border: 1px solid #f5c6cb;
  }
  
  .status-message .close-button {
    background: none;
    border: none;
    color: inherit;
    font-size: 20px;
    cursor: pointer;
    padding: 0 5px;
    margin-left: 10px;
  }
  
  @keyframes slideIn {
    from {
      transform: translateX(100%);
      opacity: 0;
    }
    to {
      transform: translateX(0);
      opacity: 1;
    }
  }
  
  /* Responsive design */
  @media (max-width: 1024px) {
    .admin-page {
      padding: 15px;
    }
    
    .admin-table {
      display: block;
      overflow-x: auto;
    }
  }
  
  /* Keep header row visible while scrolling */
  .admin-table thead {
    position: sticky;
    top: 0;
    background-color: #f8f9fa;
    z-index: 1;
  }
  
  /* Make sure select options are also black */
  .admin-table select option {
    color: #000000;
  }
  
  /* Ensure table header text remains visible on sticky header */
  .admin-table th {
    background-color: #f8f9fa;
    color: #000000;
    font-weight: 600;
    white-space: nowrap;
    text-align: left;
    direction: ltr;
    padding: 12px 15px;
    width: auto;
    min-width: max-content;
  }
  
  /* Specific column widths */
  .admin-table th.col-id {
    width: 80px;
  }
  
  .admin-table th.col-name {
    width: 150px;
  }
  
  .admin-table th.col-version {
    width: 80px;
  }
  
  .admin-table th.col-difficulty {
    width: 80px;
  }
  
  .admin-table th.col-complexity {
    width: 120px;
  }
  
  .admin-table th.col-recursive {
    width: 80px;
  }
  
  .admin-table th.col-loops {
    width: 80px;
  }
  
  .admin-table th.col-actions {
    width: 120px;
  }
  
  .admin-table th.col-question {
    width: 150px;
  }
  
  .admin-table th.col-input {
    width: 200px;
  }
  
  .admin-table th.col-expected {
    width: 200px;
  }
  
  .admin-table th.col-allow {
    width: 120px;
  }
  
  /* For cells that should wrap (like description/text fields) */
  .admin-table td.wrap-text {
    white-space: normal;
    word-break: break-word;
  }
  
  /* For cells that need horizontal scrolling */
  .scroll-text {
    max-width: 200px;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }
  
  /* Style the scrollbar for better visibility */
  .scroll-text::-webkit-scrollbar {
    height: 8px;
  }
  
  .scroll-text::-webkit-scrollbar-track {
    background: #f1f1f1;
  }
  
  .scroll-text::-webkit-scrollbar-thumb {
    background: #888;
    border-radius: 4px;
  }
  
  .admin-table tr {
    cursor: pointer;
    background-color: #ffffff;
  }
  
  .admin-table tr.selected {
    background-color: #ffeb3b;
  }
  
  .admin-table tbody tr {
    background-color: #ffffff;
    cursor: pointer;
  }
  
  .admin-table tbody tr.selected {
    background-color: #ffeb3b !important;
  }
  
  /* Base styles for the table */
  .admin-table tbody tr {
    cursor: pointer;
    transition: background-color 0.3s;
  }
  
  /* Style for selected row */
  .admin-table tbody tr.selected {
    background-color: yellow;
  }
  
  /* Make table cells inherit their row's background color */
  .admin-table td {
    background-color: inherit !important;
  }
  
  /* Ensure select dropdowns don't get too tall */
  .admin-table select {
    min-height: 40px;
  }
  
  .admin-table button.delete {
    background-color: #ff4444;
    color: white;
  }
  
  .admin-table button.duplicate {
    background-color: #ffa500;
    color: white;
  }
  
  .admin-table tr.duplicate-row {
    background-color: #ffffff !important;
    cursor: default;
  }
  
  .admin-table tr.duplicate-row td {
    background-color: #ffffff !important;
  }
  
  /* Minimal width columns */
  .col-id {
    width: 40px;  /* Even smaller for ID */
  }
  
  .col-version {
    width: 50px;  /* Just enough for version numbers */
  }
  
  .col-difficulty {
    width: 45px;  /* Numbers only */
  }
  
  .col-time-complexity {
    width: 70px;  /* Enough for "O(nlogn)" */
  }
  
  .col-checkbox {
    width: 45px;  /* Just for Yes/No or checkbox */
    text-align: center;
  }
  
  .col-max-loops {
    width: 45px;  /* Numbers only */
  }
  
  /* Make inputs in these columns match the width */
  .admin-table td input[type="number"],
  .admin-table td input[type="checkbox"] {
    width: calc(100% - 4px);
    min-width: 30px;  /* Ensure minimum clickable area */
  }
  
  /* Adjust select for time complexity */
  .admin-table td select {
    width: calc(100% - 4px);
    padding: 1px 2px;  /* Smaller padding */
    font-size: 0.9rem;
  }
  
  /* Use more specific selectors */
  .admin-table th.col-id,
  .admin-table td:first-child {
    width: 40px !important;
    max-width: 40px !important;
  }
  
  .admin-table th.col-version,
  .admin-table td:nth-child(4) {
    width: 50px !important;
    max-width: 50px !important;
  }
  
  .admin-table th.col-difficulty,
  .admin-table td:nth-child(5) {
    width: 45px !important;
    max-width: 45px !important;
  }
  
  .admin-table th.col-time-complexity,
  .admin-table td:nth-child(6) {
    width: 70px !important;
    max-width: 70px !important;
  }
  
  .admin-table th.col-checkbox,
  .admin-table td:nth-child(7) {
    width: 45px !important;
    max-width: 45px !important;
    text-align: center;
  }
  
  .admin-table th.col-max-loops,
  .admin-table td:nth-child(8) {
    width: 45px !important;
    max-width: 45px !important;
  }
  
  .admin-table th.col-actions,
  .admin-table td:last-child {
    width: 180px !important;
    max-width: 180px !important;
  }
  
  /* Make sure table takes full width but doesn't overflow */
  .table-container {
    width: 100%;
    overflow-x: auto;
  }
  
  .admin-table {
    width: 100%;
    table-layout: fixed;
  }
  
  /* Minimal columns - make them as narrow as possible */
  .admin-table th.col-id,
  .admin-table td:first-child {
    width: 30px !important;
    max-width: 30px !important;
  }
  
  .admin-table th.col-version,
  .admin-table td:nth-child(4) {
    width: 40px !important;
    max-width: 40px !important;
  }
  
  .admin-table th.col-difficulty,
  .admin-table td:nth-child(5) {
    width: 35px !important;
    max-width: 35px !important;
  }
  
  .admin-table th.col-time-complexity,
  .admin-table td:nth-child(6) {
    width: 60px !important;
    max-width: 60px !important;
  }
  
  .admin-table th.col-checkbox,
  .admin-table td:nth-child(7) {
    width: 35px !important;
    max-width: 35px !important;
    text-align: center;
  }
  
  .admin-table th.col-max-loops,
  .admin-table td:nth-child(8) {
    width: 35px !important;
    max-width: 35px !important;
  }
  
  /* Actions column - keep the same */
  .admin-table th.col-actions,
  .admin-table td:last-child {
    width: 180px !important;
    max-width: 180px !important;
  }
  
  /* Wider columns for text content */
  /* For Questions table */
  .admin-table th.col-name,
  .admin-table td:nth-child(2) {
    width: 200px !important;
    min-width: 200px !important;
  }
  
  .admin-table th.col-text,
  .admin-table td:nth-child(3) {
    width: 300px !important;
    min-width: 300px !important;
  }
  
  /* For Tests table */
  .admin-table th.col-question-name,
  .admin-table td:nth-child(2) {
    width: 200px !important;
    min-width: 200px !important;
  }
  
  .admin-table th.col-test-name,
  .admin-table td:nth-child(3) {
    width: 200px !important;
    min-width: 200px !important;
  }
  
  .admin-table th.col-input,
  .admin-table td:nth-child(4) {
    width: 200px !important;
    min-width: 200px !important;
  }
  
  .admin-table th.col-expected,
  .admin-table td:nth-child(5) {
    width: 200px !important;
    min-width: 200px !important;
  }
  
  /* Make sure the text in wider columns can wrap */
  .admin-table td {
    white-space: normal;
    word-wrap: break-word;
  }
  
  /* For inputs in narrow columns */
  .admin-table input[type="number"],
  .admin-table input[type="checkbox"] {
    padding: 1px 2px !important;
    font-size: 0.9rem !important;
  }
  
  /* Table Container */
  .admin-page {
    padding: 20px;
    width: 100%;
    box-sizing: border-box;
  }
  
  /* Table Styles */
  .display-table {
    width: 100%;
    border-collapse: collapse;
    table-layout: fixed; /* This ensures columns maintain their widths */
  }
  
  /* Column Widths */
  .col-id {
    width: 5%;
  }
  
  .col-name {
    width: 15%;
  }
  
  .col-text {
    width: 25%;
  }
  
  .col-version {
    width: 7%;
  }
  
  .col-difficulty {
    width: 7%;
  }
  
  .col-time-complexity {
    width: 12%;
  }
  
  .col-checkbox {
    width: 8%;
    text-align: center;
  }
  
  .col-max-loops {
    width: 8%;
  }
  
  .col-actions {
    width: 13%;
  }
  
  /* Test Table Specific Columns */
  .col-question-name {
    width: 20%;
  }
  
  .col-test-name {
    width: 15%;
  }
  
  .col-input {
    width: 22%;
  }
  
  .col-expected {
    width: 22%;
  }
  
  /* Header Styles */
  .display-table th {
    padding: 8px;
    background-color: #f5f5f5;
    border: 1px solid #ddd;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    font-weight: bold;
  }
  
  /* Cell Styles */
  .display-table td {
    padding: 8px;
    border: 1px solid #ddd;
    vertical-align: middle;
  }
  
  /* Scrollable Content */
  .scroll-text {
    max-height: 100px;
    overflow-y: auto;
    white-space: pre-wrap;
    word-break: break-word;
  }
  
  /* Input Fields */
  .display-table input[type="text"],
  .display-table input[type="number"],
  .display-table select,
  .display-table textarea {
    width: 100%;
    padding: 4px;
    box-sizing: border-box;
  }
  
  .display-table textarea {
    min-height: 60px;
    resize: vertical;
  }
  
  /* Checkbox Alignment */
  .display-table input[type="checkbox"] {
    margin: 0;
    vertical-align: middle;
  }
  
  /* Action Buttons */
  .display-table button {
    margin: 2px;
    padding: 4px 8px;
    white-space: nowrap;
  }
  
  /* Responsive Design */
  @media screen and (max-width: 1024px) {
    .display-table {
      display: block;
      overflow-x: auto;
      white-space: nowrap;
    }
  }
  
  /* Selected Row Styling */
  .question-row.selected {
    background-color: #e8f0fe;
  }
  
  /* Hover Effects */
  .display-table tbody tr:hover {
    background-color: #f8f9fa;
  }
  
  /* Update column selectors to match new order */
  .admin-table td:nth-child(1),
  .admin-table th:nth-child(1) {
    width: 13%;  /* Actions column */
  }
  
  .admin-table td:nth-child(2),
  .admin-table th:nth-child(2) {
    width: 5%;   /* ID column */
  }
  
  /* ... adjust other column selectors accordingly */
  
  /* Add these styles for loading states */
  .loading-overlay {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: rgba(255, 255, 255, 0.8);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1000;
  }
  
  .loading-spinner {
    width: 50px;
    height: 50px;
    border: 5px solid #f3f3f3;
    border-top: 5px solid #3498db;
    border-radius: 50%;
    animation: spin 1s linear infinite;
  }
  
  @keyframes spin {
    0% { transform: rotate(0deg); }
    100% { transform: rotate(360deg); }
  }
  
  /* Add disabled state styles */
  button:disabled {
    opacity: 0.6;
    cursor: not-allowed;
  }
  
  .table-actions button:disabled {
    opacity: 0.6;
    cursor: not-allowed;
  }
  
  /* Column widths */
  .admin-table .narrow-col {
    width: 80px;
    min-width: 80px;
    max-width: 80px;
  }
  
  .admin-table .wide-col {
    width: 200px;
    min-width: 200px;
  }
  
  /* Center checkbox in narrow column */
  .admin-table .narrow-col input[type="checkbox"] {
    margin: 0 auto;
    display: block;
  }
  
  /* Make textareas fill the wide columns */
  .admin-table .wide-col textarea {
    width: 95%;
    min-height: 60px;
  }
  
  /* Center Yes/No text in narrow column */
  .admin-table .narrow-col {
    text-align: center;
  }
  
  /* Add scrollable container for tables */
  .tests-section .table-container {
    width: 100%;
    overflow-x: auto;
    margin-bottom: 20px;
    /* Ensure smooth scrolling on touch devices */
    -webkit-overflow-scrolling: touch;
  }
  
  /* Set minimum widths for the test table */
  .tests-section .admin-table {
    min-width: 1200px; /* Ensure table is wide enough to show all columns */
    margin: 0; /* Remove margin since container handles spacing */
  }
  
  /* Adjust column widths for test table */
  .tests-section .admin-table .col-actions {
    width: 120px;
    min-width: 120px;
  }
  
  .tests-section .admin-table .col-id {
    width: 60px;
    min-width: 60px;
  }
  
  .tests-section .admin-table .col-name {
    width: 150px;
    min-width: 150px;
  }
  
  .tests-section .admin-table .col-input {
    width: 180px;
    min-width: 180px;
  }
  
  .tests-section .admin-table .col-expected {
    width: 180px;
    min-width: 180px;
  }
  
  .tests-section .admin-table .narrow-col {
    width: 60px;
    min-width: 60px;
    max-width: 60px;
  }
  
  .tests-section .admin-table .wide-col {
    width: 180px;
    min-width: 180px;
  }
  
  /* Make sure header stays fixed while scrolling horizontally */
  .tests-section .admin-table thead th {
    position: sticky;
    top: 0;
    background: #f8f9fa;
    z-index: 1;
  }
  
  /* Add spacing between list items in the admin page */
  .admin-page ul li {
    margin-bottom: 30px; /* Adjust the value as needed for more or less space */
  }
  
  .search-container {
    margin: 20px 0;
    width: 100%;
    display: flex;
    justify-content: flex-start;
  }
  
  .search-input {
    padding: 8px 12px;
    font-size: 16px;
    border: 1px solid #ddd;
    border-radius: 4px;
    width: 300px;
    transition: border-color 0.3s ease;
  }
  
  .search-input:focus {
    outline: none;
    border-color: #007bff;
    box-shadow: 0 0 0 2px rgba(0,123,255,0.25);
  }
  
  .search-input::placeholder {
    color: #999;
  }
  
  .search-container {
    display: flex;
    gap: 10px;
    align-items: center;
  }
  
  .upload-button {
    padding: 8px 16px;
    background-color: #8e58ad;
    color: white;
    border: none;
    border-radius: 4px;
    cursor: pointer;
    font-size: 14px;
    transition: background-color 0.3s;
  }
  
  .upload-button:hover {
    background-color: #8238ad;
  }
  
  .upload-button:disabled {
    background-color: #cccccc;
    cursor: not-allowed;
  }
  
  /* Add specific styles for testables section */
  .testables-section {
    margin-top: 40px !important;  /* Increase spacing from tests section */
  }
  
  /* Optional: Add consistent spacing for tests section too */
  .tests-section {
    margin-top: 40px;
  }
  
  /* Add these to your existing AdminPage.css */
  .col-solution {
    width: 120px;
  }
  
  .solution-cell {
    padding: 8px;
    text-align: center;
  }
  
  .solution-cell.has-solution {
    background-color: #d4edda !important; /* Light green */
  }
  
  .solution-cell.no-solution {
    background-color: #f8d7da !important; /* Light red */
  }
  
  .edit-solution-button {
    padding: 6px 12px;
    background-color: #007bff;
    color: white;
    border: none;
    border-radius: 4px;
    cursor: pointer;
  }
  
  .edit-solution-button:hover {
    background-color: #0056b3;
  }
  
  /* Add loading state styles */
  .edit-solution-button:disabled {
    opacity: 0.7;
    cursor: not-allowed;
  }
  
  .section-controls {
    display: flex;
    gap: 10px;
    align-items: center;
    flex: 1; /* Take up remaining space */
  }
  
  .search-input {
    padding: 8px;
    border: 1px solid #ddd;
    border-radius: 4px;
    min-width: 250px;
    font-size: 14px;
    margin: 0 auto 0 100px; /* Center with offset from title */
  }
  
  .search-input:focus {
    outline: none;
    border-color: #007bff;
    box-shadow: 0 0 0 2px rgba(0,123,255,.25);
  }
  
  /* Center table headers */
  .admin-table th {
    text-align: center;
    vertical-align: middle;
  }
  
  /* Keep the content cells as they are */
  .admin-table td {
    vertical-align: middle;
  }
  
  .text-cell {
    padding: 8px;
    text-align: center;
  }
  
  .text-cell.has-text {
    background-color: #d4edda !important;
  }
  
  .text-cell.no-text {
    background-color: #f8d7da !important;
  }
  
  .edit-text-button {
    padding: 6px 12px;
    background-color: #007bff;
    color: white;
    border: none;
    border-radius: 4px;
    cursor: pointer;
  }
  
  .edit-text-button:hover {
    background-color: #0056b3;
  }
  
  .edit-text-button:disabled {
    opacity: 0.7;
    cursor: not-allowed;
  }
  
  /* Column width styles */
  .admin-table th.col-text,
  .admin-table td:nth-child(5) {  /* The text column */
    width: 10px;  /* Match solution column width */
    max-width: 10px;
  }
  
  /* Ensure text column button doesn't overflow */
  .edit-text-button {
    width: 100px;  /* Restore original button width */
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
  
  /* Question table column widths */
  .admin-table th.col-actions,
  .admin-table td:nth-child(1) {
    width: 120px;
    max-width: 120px;
  }
  
  .admin-table th.col-id,
  .admin-table td:nth-child(2) {
    width: 60px;
    max-width: 60px;
  }
  
  .admin-table th.col-name,
  .admin-table td:nth-child(3) {
    width: 200px;
    max-width: 200px;
  }
  
  .admin-table th.col-solution,
  .admin-table td:nth-child(4),
  .admin-table th.col-text,
  .admin-table td:nth-child(5) {
    width: 85px !important;
    min-width: 85px !important;
    max-width: 85px !important;
    padding: 4px;
    overflow: hidden;  /* Prevent content from expanding column */
  }
  
  .admin-table th.col-difficulty,
  .admin-table td:nth-child(6) {
    width: 80px;
    max-width: 80px;
  }
  
  /* Target the text column header and cells directly */
  .admin-table th:nth-child(5),
  .admin-table td:nth-child(5) {
    width: 85px !important;
    min-width: 85px !important;
    max-width: 85px !important;
    padding: 4px;
    overflow: hidden;
  }
  
  /* Ensure the text content doesn't force expansion */
  .admin-table td:nth-child(5) {
    white-space: nowrap;
    text-overflow: ellipsis;
  }
  
  /* Tag styles */
  .tags-container {
    display: flex;
    flex-wrap: wrap;
    gap: 4px;
    padding: 2px;
  }
  
  .tag-label {
    display: inline-block;
    padding: 2px 8px;
    border-radius: 12px;
    font-size: 12px;
    color: white;
    white-space: nowrap;
    margin: 2px;
    box-shadow: 0 1px 2px rgba(0, 0, 0, 0.2);
  }
  
  /* Ensure tag column has reasonable width */
  .admin-table th.col-tags,
  .admin-table td:nth-child(4) {
    min-width: 120px;
    max-width: 200px;
  }
  
  /* Dialog styles */
  .dialog-overlay {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(0, 0, 0, 0.5);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1000;
  }
  
  .dialog-content {
    background: white;
    padding: 20px;
    border-radius: 8px;
    max-width:  1100px !important; /* Increased from default */
    width: 100%;
    max-height: 90vh;
    overflow-y: auto;
    position: relative;
  }
  
  .dialog-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 20px;
  }
  
  .dialog-header h2 {
    margin: 0;
    font-size: 1.5em;
  }
  
  .dialog-close {
    background: none;
    border: none;
    font-size: 24px;
    cursor: pointer;
    color: #666;
    padding: 0 8px;
  }
  
  .dialog-close:hover {
    color: #000;
  }
  
  /* User details styles */
  .user-details {
    padding: 15px;
  }
  
  .detail-row {
    display: flex;
    margin-bottom: 12px;
    padding-bottom: 8px;
    border-bottom: 1px solid #eee;
  }
  
  .detail-row:last-child {
    margin-bottom: 0;
    padding-bottom: 0;
    border-bottom: none;
  }
  
  .detail-label {
    width: 120px;
    color: #666;
    font-weight: 500;
  }
  
  .detail-value {
    flex: 1;
    color: #333;
  }
  
  /* User details dialog styles */
  .stats-section {
    margin-bottom: 20px;
  }
  
  .stats-grid {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    gap: 30px; /* Increased gap for better spacing */
    margin: 20px 0;
  }
  
  .stat-item {
    background: #f8f9fa;
    padding: 15px;
    border-radius: 8px;
    text-align: center;
  }
  
  .stat-label {
    display: block;
    color: #666;
    font-size: 0.9em;
    margin-bottom: 5px;
  }
  
  .stat-value {
    display: block;
    font-size: 1.5em;
    font-weight: 500;
    color: #333;
  }
  
  .performance-table {
    width: 100%;
    border-collapse: collapse;
    margin-top: 15px;
  }
  
  .performance-table th,
  .performance-table td {
    padding: 8px;
    border: 1px solid #ddd;
    text-align: left;
  }
  
  .performance-table th {
    background-color: #f8f9fa;
    font-weight: 500;
  }
  
  .result-success {
    color: #28a745;
  }
  
  .result-failure {
    color: #dc3545;
  }
  
  .loading, .error, .no-data {
    padding: 20px;
    text-align: center;
    color: #666;
  }
  
  .error {
    color: #dc3545;
  }
  