.compiler-page {
  background-image: url('../assets/practice_background.jpg'); /* Update the path accordingly */
  background-size: contain;
  background-position: right center; /* Centers the background image */
  background-repeat: no-repeat; /* Prevents the image from repeating */
  height: 100vh; /* Full viewport height */
  margin: 0; /* Remove default margin */
  padding: 0; /* Remove default padding */
  background-color: #000;
  position: relative;
  z-index: 1;
  overflow: visible;
} 

/* General container styles */
.editor-container {
  padding: 20px;
  font-family: Arial, sans-serif;
  color: #ffffff;
  display: flex;
  flex-direction: column;
  align-items: center;
  min-height: 100vh; /* Ensure full height */
  overflow: visible;
}

.monaco-editor {
  direction: ltr !important; /* Force the Monaco editor to be left-to-right */
  height: 100% !important;
}
/* Control panel styles */
.control-panel {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  margin-bottom: 20px;
  background: transparent;
  direction: ltr !important; /* Force LTR direction */
}

.control-panel-left {
  display: flex;
  align-items: center;
  gap: 15px;
  order: 1 !important; /* Force editor controls to left */
}

.control-panel-right {
  display: flex;
  align-items: center;
  gap: 15px;
  order: 2 !important; /* Force PDF controls to right */
}

/* Theme toggle button styles */
.theme-toggle-button {
  padding: 5px 10px;
  cursor: pointer;
  border: 1px solid #ccc;
  border-radius: 4px;
  background-color: #333;
  color: #fff;
  margin-right: 15px;
}

.theme-toggle-button.light {
  background-color: #f5f5f5;
  color: #000;
}

/* Font size dropdown styles */
.font-size-select {
  padding: 5px;
  cursor: pointer;
  border: 1px solid #ccc;
  border-radius: 4px;
}

/* Specific button styles for run and submit */
.button-group {
  display: flex;
  justify-content: center;
  gap: 20px;
  margin-top: -50px;
  margin-right: 400px;
  position: relative;
  z-index: 10;
}

.button-group .run-button,
.button-group .submit-button {
  min-width: 120px;
  padding: 10px 20px;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  font-size: 16px;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, sans-serif;
  font-weight: 500;
  color: white;
  display: inline-block;
  text-align: center;
  transition: background-color 0.3s;
  margin: 0;
  line-height: normal;
  height: auto;
  box-sizing: border-box;
  text-transform: none;
  position: relative;
  z-index: 10;
}

.button-group .run-button {
  background-color: #09b70f;
}

.button-group .submit-button {
  background-color: #7209b7;
}

.button-group .run-button:hover,
.button-group .submit-button:hover {
  opacity: 0.9;
}

.button-group .run-button:disabled,
.button-group .submit-button:disabled {
  background-color: #ccc;
  cursor: not-allowed;
  opacity: 0.6;
}

/* Loading spinner inside buttons */
.button-group .loading-spinner {
  width: 16px;
  height: 16px;
  border: 3px solid rgba(255, 255, 255, 0.2);
  border-top: 3px solid white;
  border-radius: 50%;
  animation: spin 1s linear infinite;
  display: inline-block;
  vertical-align: middle;
  margin: 0 5px;
}

/* Output box container */
.output-box-container {
  width: 80%;  /* Increase the width */
  max-width: 800px; /* Set a max width for large screens */
  margin-top: 2rem;
  position: relative;
}

/* Title container with dynamic alignment based on language direction */
.output-box-title {
  margin-bottom: 0.5rem;
  text-align: center; /* Default for LTR */
}

.rtl .output-box-title {
  text-align: center; /* Keep it centered even for RTL */
}

/* Output box */
.output-box-code {
  height: 200px; /* Increase height to make it larger */
  max-height: 300px; /* Set a max height */
  overflow-y: auto; /* Enable vertical scrolling */
  padding: 1rem;
  background-color: #1e1e1e;
  color: white;
  border-radius: 5px;
  border: 1px solid #ccc;
  white-space: pre-wrap; /* Ensure line breaks are respected */
  text-align: left; /* Ensure text is always left-aligned in the box */
  direction: ltr !important;
}

.close-output-btn {
  position: absolute;
  right: 0; 
  background-color: transparent;
  color: white;
  border: none;
  font-size: 1.5rem;
  cursor: pointer;
  padding: 0 10px;
  line-height: 1.5;
}

.close-output-btn:hover {
  color: yellow;
}

/* Add scrollbar styling if needed */
.output-box-code::-webkit-scrollbar {
  width: 8px;
}

.output-box-code::-webkit-scrollbar-thumb {
  background-color: #888;
  border-radius: 10px;
}

.output-box-code::-webkit-scrollbar-thumb:hover {
  background-color: #555;
}

.useless-functions-container {
  background-color: #e6f7ff; /* Soft blue background */
  border: 5px solid #91d5ff; /* Light blue border */
  padding: 15px;
  border-radius: 5px;
  margin-top: 20px; /* Space above the box */
  margin: 20px auto 0 auto; /* Center the box horizontally */  
  width: 50%;                
  text-align: start;
}

.useless-functions-text {
  margin: 10px 0;
  font-size: 16px;
  color: #000; /* Black text */
}

.file-upload-button {
  padding: 5px 10px;
  cursor: pointer;
  margin-left: 10px;
  background-color: #333;
  color: #fff;
  border: 1px solid #ccc;
  border-radius: 4px;
}

.file-upload-button i {
  font-size: 16px;
}

.editor-pdf-layout {
  display: flex;
  gap: 20px;
  margin-bottom: 5px;
  width: 100%;
  min-height: 500px;
  flex-direction: row !important; /* Force LTR layout */
  direction: ltr !important; /* Force LTR direction */
  position: relative;
  z-index: 1;
}

.monaco-container {
  flex: 3;
  min-width: 500px;
  display: flex;
  flex-direction: column;
  height: 450px;
  order: 1 !important; /* Force editor to be first always */
  position: relative;
  z-index: 1;
  pointer-events: none;
}

.monaco-editor {
  flex: 1;
  height: 400px !important; /* Set exact height to match editor */
  pointer-events: auto;
}

.monaco-container::before {
  content: '';
  position: absolute;
  top: 10px;
  left: 10px;
  display: flex;
  gap: 8px;
  z-index: 1;
}

.window-controls {
  position: absolute;
  top: 10px;
  left: 10px;
  display: flex;
  gap: 8px;
  z-index: 1;
  padding: 5px;
}

.pdf-section {
  flex: 2;
  min-width: 400px;
  display: flex;
  flex-direction: column;
  height: 450px;
  order: 2 !important;
  position: relative;
  z-index: 1;
  pointer-events: auto;
}

.pdf-container {
  flex: 1;
  background: #f5f5f5;
  border-radius: 8px;
  display: flex;
  flex-direction: column;
  margin: 0;
  height: 400px;
  pointer-events: auto;
}

/* Fixed window controls bar for PDF */
.window-dots {
  display: flex;
  gap: 8px;
  padding: 8px 10px;
  background-color: #e4e4e4;
  width: 100%;
  border-top-left-radius: 8px;
  border-top-right-radius: 8px;
  box-sizing: border-box;
  flex-shrink: 0;  /* Prevent the bar from shrinking */
}

/* PDF document container */
.react-pdf__Document {
  pointer-events: auto;  /* Enable scrolling */
  flex: 1;
  overflow-y: auto !important;
  padding: 10px;
  display: flex;
  flex-direction: column;
  align-items: center;
}

/* Remove any overflow restrictions on the Page component */
.react-pdf__Page {
  overflow: visible !important;
}

/* Remove the conflicting max-height */
.react-pdf__Page {
  max-height: none !important;
}

/* PDF controls styles */
.pdf-controls {
  margin-top: 0;
  width: 100%;
  padding: 10px;
  background: rgba(94, 86, 86, 0.5);  /* Same color as before but with 0.5 alpha */
  border-radius: 4px;
  box-shadow: none;
  display: flex;
  justify-content: center;
  gap: 20px;
  align-items: center;
}

.pdf-zoom-controls {
  display: flex;
  align-items: center;
  gap: 10px;
  padding-left: 10px;
  border-left: 1px solid #ccc;
}

/* Move PDF controls to the control panel */
.pdf-controls button,
.pdf-zoom-controls button {
  padding: 5px 10px;
  border: none;
  background: #007bff;
  color: white;
  border-radius: 4px;
  cursor: pointer;
  font-size: 14px;  /* Match other control sizes */
}

.pdf-controls button:disabled,
.pdf-zoom-controls button:disabled {
  background: #ccc;
  cursor: not-allowed;
}

.pdf-controls span,
.pdf-zoom-controls span {
  font-size: 14px;
  color: #ffffff;
}

/* Update responsive styles */
@media (max-width: 1200px) {
  .editor-pdf-layout {
    min-height: auto; /* Allow containers to determine height */
    overflow-x: auto; /* Add horizontal scroll instead of wrapping */
    flex-wrap: nowrap; /* Prevent wrapping */
  }

  .monaco-container,
  .pdf-section {
    min-height: 500px; /* Maintain minimum height */
  }
}

.page-input-container {
    display: flex;
    align-items: center;
    gap: 5px;
}

.page-input {
    width: 40px;
    padding: 2px 5px;
    border: 1px solid #ccc;
    border-radius: 4px;
    text-align: center;
    font-size: 14px;
}

.page-input:focus {
    outline: none;
    border-color: #007bff;
}

/* Update PDF controls style */
.pdf-controls button {
    min-width: 30px; /* Make buttons consistent width */
    padding: 5px;
    text-align: center;
}

/* Add this to override any RTL styles */
[dir="rtl"] .editor-pdf-layout {
  flex-direction: row !important;
  direction: ltr !important;
}

[dir="rtl"] .monaco-container {
  order: 1 !important;
}

[dir="rtl"] .pdf-section {
  order: 2 !important;
}

/* Add RTL overrides */
[dir="rtl"] .control-panel {
  flex-direction: row !important;
  direction: ltr !important;
}

[dir="rtl"] .control-panel-left {
  order: 1 !important;
}

[dir="rtl"] .control-panel-right {
  order: 2 !important;
}

.syntax-error-container {
    background-color: #fff3f3;
    border: 2px solid #ff4444;
    border-radius: 5px;
    padding: 15px;
    margin: 20px 0;
    width: 100%;
    max-width: 800px;
}

.syntax-error-container h3 {
    color: #cc0000;
    margin-top: 0;
    text-align: center;  /* Center the heading */
}

.error-message {
    background-color: #1e1e1e;
    color: #ff6b6b;
    padding: 15px;
    border-radius: 4px;
    white-space: pre-wrap;
    font-family: monospace;
    margin: 0;
}

/* Tooltip styles */
td.tooltip {
  position: relative;
  cursor: pointer;
}

.tooltip-content {
  visibility: hidden;
  position: absolute;
  z-index: 1000;
  background-color: #2d2d2d;
  color: #fff;
  text-align: left;
  direction: ltr !important; /* Force LTR */
  border-radius: 6px;
  padding: 15px;
  min-width: 300px;
  max-width: 500px;
  font-size: 14px;
  line-height: 1.5;
  white-space: pre-wrap;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  opacity: 0;
  transition: visibility 0s, opacity 0.2s;
  left: 50%;
  transform: translateX(-50%);
  bottom: 100%;
  margin-bottom: 0;
}

td.tooltip:hover .tooltip-content {
  visibility: visible;
  opacity: 1;
  bottom: auto;                 /* Reset bottom */
  top: 100%;                    /* Position directly below */
  margin-top: 0;                /* Remove space */
}

.tooltip-section {
  margin: 5px 0;
  padding: 5px 0;
  border-bottom: 1px solid #444;
}

.tooltip-section:last-child {
  border-bottom: none;
}

.tooltip-label {
  color: #888;
  font-size: 12px;
  margin-bottom: 3px;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.copy-button {
  background: none;
  border: none;
  color: #888;
  cursor: pointer;
  padding: 2px 6px;
  font-size: 12px;
  transition: color 0.2s;
}

.copy-button:hover {
  color: #fff;
}

.copy-button i {
  margin-left: 5px;
}

.tooltip-value {
  font-family: monospace;
  color: #fff;
  user-select: text;
  direction: ltr !important; /* Force LTR */
  text-align: left !important; /* Force left alignment */
  unicode-bidi: plaintext; /* Ensure numbers and code are displayed correctly */
}

/* Specific tooltip positioning for compiler page */
.compiler-page td.tooltip .tooltip-content {
  top: 40px !important;    
  bottom: auto;
  /* position: fixed;           */
  /* bottom: 100%;             */
  top: auto !important;    
  left: 50%;
  transform: translateX(-50%);
  z-index: 999999;         /* Very high z-index to stay above everything */
  pointer-events: none;
}

.compiler-page td.tooltip:hover .tooltip-content {
  top: 40px !important;   
  bottom: auto;
  /* position: fixed; */
  /* bottom: 100%; */
  top: auto !important;
  left: 50%;
  transform: translateX(-50%) translateY(-110%);
  visibility: visible;
  opacity: 1;
  pointer-events: auto;
  z-index: 999999;
}

/* Ensure proper positioning context */
.compiler-page td.tooltip {
  position: relative;
}

/* Ensure table cells don't create stacking contexts */
.compiler-page td {
  position: static;
}

.compiler-page table {
  position: relative;
  z-index: 99999;
  overflow: visible;  /* Allow content to overflow */
}

.results-table-container {
  overflow: visible;
}

.table-container {
  overflow: visible;
}

.editor-container {
  overflow: visible;
}

/* Add window controls to Monaco editor */
.monaco-container {
  position: relative;  /* For positioning the controls */
}

.monaco-container::before {
  content: '';
  position: absolute;
  top: 10px;
  left: 10px;
  display: flex;
  gap: 8px;
  z-index: 1;
}

.window-controls {
  position: absolute;
  top: 10px;
  left: 10px;
  display: flex;
  gap: 8px;
  z-index: 1;
  padding: 5px;
}

/* Add window controls to PDF container */
.pdf-container {
  position: relative;  /* For positioning the controls */
}

.pdf-container::before {
  content: '';
  position: absolute;
  top: 10px;
  left: 10px;
  display: flex;
  gap: 8px;
  z-index: 1;
}

/* Shared window control styles */
.window-dots {
  display: flex;
  gap: 8px;
  padding: 8px 10px;
  background-color: #e4e4e4;
  width: 100%;
  border-top-left-radius: 8px;
  border-top-right-radius: 8px;
  box-sizing: border-box;
}

.window-dots span {
  width: 12px;
  height: 12px;
  border-radius: 50%;
  display: inline-block;
}

.red-dot {
  background-color: #ff5f56;
}

.yellow-dot {
  background-color: #ffbd2e;
}

.green-dot {
  background-color: #27c93f;
}

/* Update container styles */
.monaco-container {
  display: flex;
  flex-direction: column;
  /* Remove position: relative since we're changing the layout */
}

/* Remove the ::before pseudo-elements since we don't need them anymore */
.monaco-container::before,
.pdf-container::before {
  display: none;
}

/* Adjust the dots */
.window-dots span {
  width: 12px;
  height: 12px;
  border-radius: 50%;
  display: inline-block;
}

/* Ensure buttons are clickable by adjusting z-indices */
.button-group {
  position: relative;
  z-index: 10;  /* Increase this if needed */
}

.run-button,
.submit-button {
  position: relative;
  z-index: 10;  /* Match parent z-index */
}

/* Adjust editor container to not block buttons */
.editor-pdf-layout {
  position: relative;
  z-index: 1;  /* Lower than buttons */
}

/* Remove any accidental overlays */
.monaco-container,
.pdf-container {
  pointer-events: none;  /* Allow clicks to pass through to buttons */
}

.monaco-editor,
.pdf-section {
  pointer-events: auto;  /* Re-enable clicks for editor and PDF */
}