.performance-page h2 {
    text-align: center;
    color: teal;
    margin-bottom: 20px;
}

.performance-page {
    min-height: 100vh;
    height: auto;
    padding-bottom: 50px;  /* Alternative approach */
}
